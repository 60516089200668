import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import("../views/login.vue"),
  },
  {
    path: "/branch",
    name: "branch",
    component: () => import("../views/Branch.vue"),
  },
  {
    path: "/cotizacion",
    name: "inicioCotizacionVista",
    component: () => import("../views/Cotizacion/registrarCotizacionView.vue"),
    // component: () => import("../views/Cotizacion/Inicio.vue"),
    children: [
      {
        path: "/cotizacion",
        name: "datoscarga",
        component: () =>
          import("../components/Cotizacion/cardDatosCargaComponent.vue"),
      },

      {
        path: "/cotizacion",
        name: "cardServicio",
        component: () =>
          import("../components/Cotizacion/cardServicioComponent.vue"),
      },
    ],
  },
  {
    path: "/ver_cotizacion/:id",
    name: "verCotizacionVista",
    component: () => import("../views/verCotizacion/verCotizacionView.vue"),
  },
  {
    path: "/editar_cotizacion/:id",
    name: "editarCotizacionVista",
    component: () =>
      import("../views/editarCotizacion/editarCotizacionView.vue"),
  },

  {
    path: "/listado",
    name: "listadoCotizacion",

    component: () =>
      import("../views/listadoCotizacion/listadoCotizacionView.vue"),
  },
  {
    path: "/pre_cotizacion",
    name: "preCotizacion",

    component: () => import("../views/Cotizacion/preCotizacionView.vue"),
    children: [
      {
        path: "/pre_cotizacion",
        name: "cardServicioPreCotizacion",
        component: () =>
          import("../components/Cotizacion/cardServicioComponent.vue"),
      },
    ],
  },
  {
    path: "/reporte",
    name: "reporteCotizacion",

    component: () =>
      import("../views/reporteCotizacion/reporteCotizacionView.vue"),
  },

  {
    path: "/reporte_abierta",
    name: "reporteAbiertaCotizacion",

    component: () =>
      import("../views/reporteCotizacion/reporteAbiertaView.vue"),
  },
  // {
  //   path: "/preview",
  //   name: "preview",

  //   component: () => import("../views/Cotizacion/preview.vue"),
  // },
  {
    path: "/ficheros",
    name: "ficheros",
    component: () => import("@/views/dashboard/Main.vue"),
    children: [
      {
        path: "",
        redirect: "/ficheros/main",
      },
      {
        path: "main",
        name: "Main",
        component: () => import("@/views/dashboard/Home.vue"),
      },
      {
        path: "folderEntities/list/:id_role",
        name: "listEntitie",
        component: () => import("@/views/folderEntities/listEntities.vue"),
      },
      {
        path: "folderEntities/control/:id_role",
        name: "controlEntitie",
        component: () => import("@/views/folderEntities/controlEntities.vue"),
      },
      {
        path: "folderEntities/control/edit/:id",
        name: "controlEntitieEdit",
        component: () => import("@/views/folderEntities/controlEntities.vue"),
      },
      // {
      //   path: "folderMaster/list/:id_role",
      //   name: "listMaster",
      //   component: ListMaster,
      // },
      // {
      //   path: "folderMaster/control",
      //   name: "controlMaster",
      //   component: controlMaster,
      // },
      // {
      //   path: "folderMaster/control/:id",
      //   name: "controlMasterId",
      //   component: controlMaster,
      // },
      // {
      //   path: "folderMaster/control/:id/:params",
      //   name: "controlMasterParams",

      //   component: controlMaster,
      // },
      // {
      //   path: "folderHouse/list/:id_role",
      //   name: "listHouse",
      //   component: ListHouse,
      // },
      // {
      //   path: "folderHouse/control",
      //   name: "controlHouse",
      //   component: controlHouse,
      // },
      // {
      //   path: "folderHouse/control/:id",
      //   name: "controlHouseId",
      //   component: controlHouse,
      // },
      // {
      //   path: "folderReports/list/:id_role",
      //   name: "reportsControlFile",
      //   component: reportControlFile,
      // },
      // {
      //   path: "folderBilling/billControlGastos/:id_house",
      //   name: "billControlGastos",
      //   component: billingControlGastos,
      // },
      // {
      //   path:
      //     "folderBilling/billControlGastos/view/:id_house/:id_control/:id_orders/:code",
      //   name: "billControlGastosControl",
      //   component: billingControlGastos,
      // },
      // {
      //   path: "folderBilling/listControlGastos/:id_role",
      //   name: "listControlGastos",
      //   component: listControlGastos,
      // },
      // {
      //   path: "folderRequest/controlRequest",
      //   name: "controlRequest",
      //   component: ControlRequest,
      // },
    ],
  },
  {
    path: "/listado_casillero",
    name: "listado-casillero",
    component: () => import("../views/casillero/listadoCasillero"),
  },
  {
    path: "/nuevo_casillero",
    name: "nuevo-casillero",
    component: () => import("../views/casillero/nuevoCasillero"),
  },
  {
    path: "/ver_casillero/:id",
    name: "ver-casillero",
    component: () => import("../views/casillero/verCasillero"),
  },
  {
    path: "/editar_casillero/:id",
    name: "editar-casillero",
    component: () => import("../views/casillero/editarCasillero"),
  },
  {
    path: "/carga_masiva",
    name: "carga-masiva-lcl",
    component: () => import("../../src/views/Calculadora/lclView"),
  },
  {
    path: "/carga_masiva_fcl",
    name: "carga-masiva-fcl",
    component: () => import("../../src/views/Calculadora/fclView"),
  },
  {
    path: "/list_user_calc",
    name: "list_user_calc",
    component: () => import("../../src/views/Calculadora/ListUserCalc"),
  },
  {
    path: "/*",
    name: "About",

    component: () => import("../views/About.vue"),
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
        // , offset: { x: 0, y: 10 }
      };
    } else {
      return savedPosition;
    }
  },
});

export default router;
