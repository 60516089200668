const mutations = {
  updateDataCarga(state) {
    state.stepCarga = !state.stepCarga;
  },
  updateDatosServicios(state) {
    state.stepServicio = !state.stepServicio;
  },
  updateDatosCostos(state) {
    state.stepCosto = !state.stepCosto;
  },
  updateDatosNotas(state) {
    state.stepNota = !state.stepNota;
  },
  setQuoteStatus(state, data) {
    state.status = data;
  },

  setEntities(state, data) {
    state.entities = data;
  },
  setModality(state, data) {
    state.modality = data;
  },
  setShipment(state, data) {
    state.shipment = data;
  },
  setIncoterms(state, data) {
    state.incoterm = data;
  },
  setPortBegin(state, data) {
    state.portBegin = data;
  },
  setPortEnd(state, data) {
    state.portEnd = data;
  },
  setServices(state, data) {
    state.services = data;
    state.condition = true;
  },
  setCostos(state, data) {
    state.costos = data;
  },

  setRole(state, datos) {
    state.roles = datos;
  },
  setMultiplicador(state, datos) {
    state.multiplicador = datos;
  },
  setNuevoUsuarioFlag(state) {
    state.nuevoUsuarioFlag = !state.nuevoUsuarioFlag;
  },

  setusuarioSeleccionadoFlag(state, data) {
    state.usuarioSeleccionadoFlag = !state.usuarioSeleccionadoFlag;
    state.datosPrincipales.idCliente = data.id;
    state.datosPrincipales.name = data.name;
    state.datosPrincipales.phone = data.phone;
  },
  setCalculadorFlag(state, data) {
    state.calculadorFlag = !state.calculadorFlag;
    state.datosPrincipales.bultos = data.bultos;
    state.datosPrincipales.metroscc = data.metroscc;
    state.datosPrincipales.kg = data.kg;
  },

  setContainers(state, data) {
    state.datosPrincipales.containers.push(data);
  },
  setContainersList(state, data) {
    state.containersList = data;
  },
  opcioneMenuLista(state, menu) {
    state.opcionMenu = menu;
  },
  //

  limpiarDatos(state) {
    state.tabCotizar = 0;
    state.step1flag = true;
    state.step2flag = false;
    state.step3flag = false;
    state.registrarFlag = false;
    state.cotizarFlag = false;
  },
  guardarDatosVer(state, datos) {
    
    state.datosPrincipales = datos;
  },
  guardarNotasContendor(state, datos) {
    state.datosPrincipales.containers = datos.containers;
    state.datosPrincipales.notesFixed = datos.notesFixed;
  },
  setDatosClientes(state, data) {
    state.listadoCliente = data;
  },
  //

  setCudFlag(state, data) {
    state.cudFlag = data;
  },
  setregistroCompleto(state, data) {
    state.registroCompleto = data;
  },

  setDialog(state, data) {
    state.dialog = data;
  },

  setbegEndList(state, data) {
    state.begEndList = data;
  },

  setearDatos(state) {
    state.datosPrincipales = {
      idstatus: 3,
      identities: JSON.parse(localStorage.getItem("datos")).id_entitie,
      idPricing: JSON.parse(localStorage.getItem("datos")).id_entitie,
      amount: null,
      idmodality: null,
      idshipment: null,
      idincoterm: null,
      idPortBegin: null,
      idPortEnd: null,
      idCliente: null,
      name: null,
      phone: null,
      bultos: null,
      metroscc: null,
      kg: null,
      idprovincia: 128,
      iddistrito: 1279,
      descripcionMercancia: "",
      notesFixed: [
        { description: "SALIDAS SEMANALES", state: 1 },
        {
          description: "LAS TARIFAS NO APLICAN PARA MERCANCIA CON SOBREPESO",
          state: 1,
        },
        {
          description: "LAS TARIFAS NO APLICAN PARA MERCANCIA PELIGROSA",
          state: 1,
        },
        {
          description:
            "LOS DOCUMENTOS DEL EMBARQUE SON RESPONSABILIDAD DEL PROVEEDOR Y EL CONSIGNATARIO",
          state: 1,
        },
        {
          description: "EL EMBALAJE DE LA CARGA DEBE SER  ACTO PARA TRANSPORTE",
          state: 1,
        },
        {
          description:
            "SI EL CANAL CONSIGANDO POR LA ADUANA ES ROJO EL COSTO DEL ALMACEN AUMENTARA EN 150-200 USD",
          state: 1,
        },
        {
          description:
            "TODOS LOS SERVICIOS QUE SE REALICEN DENTRO DEL TERRITORIO PERUANO TIENEN UN IGV DEL 18%",
          state: 1,
        },
        {
          description:
            "COSTOS ADICIONALES POR INSPECCIONES SUGERIDAS DE ADUANA",
          state: 1,
        },
      ],
      containers: [],
      impuestos: [
        { type: 1, name: "FOB", percentage: 0, valor: null, orden: 1 },
        { type: 2, name: "FLETE", percentage: 0, valor: null, orden: 2 },
        { type: 3, name: "SEGURO", percentage: 1.5, valor: null, orden: 3 },
        { type: 4, name: "CIF", percentage: null, valor: null, orden: 4 },
        { type: 5, name: "AD VALOREN", percentage: 6, valor: null, orden: 5 },
        { type: 6, name: "ISC", percentage: 0.0, valor: null, orden: 6 },
        { type: 7, name: "IPM", percentage: 2.0, valor: null, orden: 7 },
        { type: 8, name: "IGV", percentage: 16.0, valor: null, orden: 8 },
        {
          type: 9,
          name: "PERCEPCIONES",
          percentage: 5.0,
          valor: null,
          orden: 9,
        },
      ],
      instructivo: [],
    };
    state.listFiile = [];
  },
  setLlamadas(state, data) {
    state.llamadas = data;
  },
  setInstructivo(state, data) {
    state.instructivo = data;
  },
  setearServices(state) {
    state.services = [];
  },
  setDialogLlamadas(state, data) {
    state.dialogLlamadas = data;
  },
  setIdQuote(state, data) {
    state.idQuote = data;
  },
  setFactor(state, data) {
    state.factor = data;
  },
  setProvincias(state, data) {
    state.provincias = data;
  },
  setDistritos(state, data) {
    state.distritos = data;
  },
  setFleteTotal(state, data) {
    state.totalDeFlete = data;
  },
  setEditarflag(state, data) {
    state.editarflag = data;
  },
  setTotalOpcion(state, data) {
    state.totalOpcion = data;
  },
  setTotalVenta(state, data) {
    state.totalVenta = data;
  },
  setTotalImpuesto(state, data) {
    state.totalImpuesto = data;
  },
  setPrecotizarFlag(state, data) {
    state.precotizacionFlag = data;
  },
  setImpuestoFlag(state) {
    state.impuestoFlag = !state.impuestoFlag;
  },

  setNoteFixed(state, notas) {
    state.datosPrincipales.notesFixed = notas;
  },
  setBotonRegistrarFlag(state, data) {
    state.botonRegistrarFlag = data;
  },

  setArchivos(state, data) {
    state.archivos = data;
  },
  setListFiile(state, data) {
    state.listFiile = data;
  },
  setArchivosFlag(state) {
    state.archivosFlag = !state.archivosFlag;
  },

  _setEntities(state, dataEntities) {
    dataEntities.map(function (item) {
      state.itemsEntities.push({
        id: item.id,
        document: item.documentlong,
        business_name: item.namelong,
        pais: item.namePais,
        state: item.nameState,
        city: item.nameCity,
        town: item.nameTown,
        address: item.address,
        location: item.location,
        notes: item.notes,
      });
    });
    state.dataList = true;
  },
  _setItemsList(state, dataItemsList) {
    state.itemsList = dataItemsList;
  },
  _setItemsMenu(state, dataItemsMenu) {
    state.itemsMenu = dataItemsMenu;

    state.dataMenu = true;
  },
  setRegistroLlamadaFlag(state) {
    state.registroLlamadaFlag = !state.registroLlamadaFlag;
  },
  setActualizarListaArchivos(state) {
    state.actualizarListaArchivos = !state.actualizarListaArchivos;
  },
  _setItemsPhone(state, data) {
    state.itemsListPhoneEdit = data;
  },
  _setItemsContacts(state, data) {
    state.itemsListContactEdit = data;
  },
  _setDocumentsPais(state, dataDocumentsPais) {
    state.itemsDocumentsPais = dataDocumentsPais;
  },
  _setPais(state, dataPais) {
    state.itemsPais = dataPais;
  },
  _setItemsDataRoleList(state, data) {
    state.itemsDataRoleList = data;
  },
  _setState(state, dataState) {
    state.itemsState = dataState;
  },
  _setCity(state, dataCity) {
    state.itemsCity = dataCity;
  },
  _setTown(state, dataTown) {
    state.itemsTown = dataTown;
  },
  _setSex(state, dataSex) {
    state.itemsSex = dataSex;
  },
  _setAgents(state, dataAgents) {
    state.itemsAgents = dataAgents;
  },
  _setConsigners(state, dataConsigner) {
    state.itemsConsigners = dataConsigner;
  },

  _setNotify(state, dataNotify) {
    state.itemsNotify = dataNotify;
  },
  _setItemsNaviera(state, dataNaviera) {
    state.itemsNavieras = dataNaviera;
  },
  _setColoaders(state, dataColoaders) {
    state.itemsColoaders = dataColoaders;
  },
  setQuoteList(state, quoteList) {
    state.quoteList = quoteList;
  },

  setQuoteCall(state, quoteCall) {
    state.quoteCall = quoteCall;
  },

  setRegistrarFlag(state) {
    state.registrarFlag = !state.registrarFlag;
  },
  _setItemsDataAccountList(state, data) {
    state.itemsDataAccountList = data;
  },

  _setItemsDataBanksList(state, data) {
    state.itemsDataBanksList = data;
  },

  _setItemsDataAccountsNumberList(state, data) {
    state.itemsDataAccountsNumberList = data;
  },
  setMarketingList(state, data) {
    state.marketingList = data;
  },
  setQuoteOpen(state, data) {
    state.quoteOpen = data;
  },
  setReportsRangeDays(state, data) {
    state.reportsRangeDays = data;
  },
  setListadoQuoteCasillero(state, data) {
    state.listadoQuoteCasillero = data;
  },
  setCasillero(state) {
    state.casillero = {
      create: null,
      volumen: 0,
      peso: 0,
      containers: 0,
      nroBultos: 0,
      ganancia: 0,
      expediente: "",
      fechaSalida: null,
      listQuote: [],
    };
  },
  setListadoCasillero(state, data) {
    state.listadoCasillero = data;
  },
  setInstructivoCasillero(state, data) {
    state.instructivoCasillero = data;
  },
  setQuoteSales(state, quoteSales) {
    state.quoteSales = quoteSales;
  },
  setCasilleroVer(state, data) {
    
    state.casillero = {
      create: data.created_at,
      volumen: data.volumen,
      peso: data.peso,
      containers: data.containers,
      nroBultos: data.nrobultos,
      ganancia: data.ganancia,
      expediente: data.expediente,
      fechaSalida: data.fechasalida,
      listQuote: data.quote,
    };
  },
  setBranch(state, data) {
    state.branch = data;
  },
  setPaisCalc(state, data) {
    state.paisCalc = data;
  },
  sePuertoCalc(state, data) {
    state.puertoCalc = data;
  },
  setMonedaCalc(state, data) {
    state.monedaCalc = data;
  },
  setnavieraCalc(state, data) {
    state.navieraCalc = data;
  },
  setcontendedorCalc(state, data) {
    state.contendedorCalc = data;
  },
  SET_DIALOG_VERSION(state,data){
    state.dialogVersion = data
  }
};
export default mutations;
