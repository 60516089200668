<template>
  <v-app>
    <v-overlay :value="overlay"></v-overlay>
    <v-main>
      <router-view />
    </v-main>
    <v-dialog v-model="$store.state.modalPhones" max-width="550">
      <v-card>
        <v-card-title class="text-h5"> Teléfonos </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-autocomplete
                :items="$store.state.itemsPhone"
                item-text="name"
                item-value="id"
                label="Tipo de Teléfono"
                return-object
                v-model="$store.state.phone_id"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="$store.state.phone_number"
                label="N°. Teléfono"
              >
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn
                v-if="
                  $store.state.phone_id != '' && $store.state.phone_number != ''
                "
                class="mx-2"
                fab
                color="teal darken-1
"
                dark
                elevation="0"
                @click="_addPhones()"
                ><v-icon x-large>mdi-plus</v-icon></v-btn
              >
            </v-col>
          </v-row>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Tipo</th>
                  <th class="text-left">N°. Teléfono</th>
                  <th class="text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) of $store.state.itemsListPhone"
                  :key="item.id"
                >
                  <td>{{ item.name }}</td>
                  <td>
                    {{ item.number }}
                  </td>

                  <td>
                    <v-btn
                      @click="_deletePhones(index)"
                      fab
                      x-small
                      elevation="0"
                      ><v-icon color="red">mdi-delete</v-icon></v-btn
                    >
                    <a
                      v-if="item.id == 2"
                      fab
                      x-small
                      elevation="0"
                      color="green"
                      dark
                      class="ml-5"
                      target="_blank"
                      style="text-decoration: none"
                      :href="`https://wa.me/51${item.number}`"
                      ><v-icon color="green">mdi-whatsapp</v-icon></a
                    >
                    <a
                      fab
                      x-small
                      elevation="0"
                      dark
                      class="ml-5"
                      style="text-decoration: none"
                      :href="`tel:+${item.number}`"
                      ><v-icon color="blue-grey darken-3">mdi-phone</v-icon></a
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="$store.state.modalPhones = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- EDITAR TELEFONO -->

    <v-dialog v-model="$store.state.modalPhonesEdit" max-width="550">
      <v-card>
        <v-card-title class="text-h5"> Teléfonos </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-autocomplete
                :items="$store.state.itemsPhone"
                item-text="name"
                item-value="id"
                label="Tipo de Teléfono"
                return-object
                v-model="$store.state.phone_id"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="$store.state.phone_number"
                label="N°. Teléfono"
              >
              </v-text-field>
            </v-col>
            <!-- <v-col cols="2">
              <v-btn
                class="mx-2"
                fab
                color="teal darken-1
"
                dark
                elevation="0"
                @click="_addPhones()"
                ><v-icon x-large>mdi-plus</v-icon></v-btn
              >
            </v-col> -->
          </v-row>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Tipo</th>
                  <th class="text-left">N°. Teléfono</th>
                  <th class="text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item of $store.state.itemsListPhoneEdit"
                  :key="item.id"
                >
                  <td>{{ item.tipo_phone }}</td>
                  <td>
                    {{ item.phone }}
                  </td>

                  <td>
                    <!--  <v-btn
                      @click="_deletePhones(index)"
                      fab
                      x-small
                      elevation="0"
                      ><v-icon color="red">mdi-delete</v-icon></v-btn
                    > -->
                    <a
                      v-if="item.tipo_phone == 'Celular'"
                      fab
                      x-small
                      elevation="0"
                      color="green"
                      dark
                      class="ml-5"
                      target="_blank"
                      style="text-decoration: none"
                      :href="`https://wa.me/51${item.number}`"
                      ><v-icon color="green">mdi-whatsapp</v-icon></a
                    >
                    <a
                      fab
                      x-small
                      elevation="0"
                      dark
                      class="ml-5"
                      style="text-decoration: none"
                      :href="`tel:+${item.number}`"
                      ><v-icon color="blue-grey darken-3">mdi-phone</v-icon></a
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="$store.state.modalPhonesEdit = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CONTACTOS -->
    <v-dialog v-model="$store.state.modalContactsEdit" max-width="750">
      <v-card>
        <v-card-title class="text-h5"> Contactos </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="7">
              <v-text-field
                label="Nombre Completo"
                v-model="$store.state.contact_name"
              >
              </v-text-field>
            </v-col>

            <v-col cols="5">
              <v-text-field
                v-model="$store.state.contact_number"
                label="N°. Teléfono"
              >
              </v-text-field>
            </v-col>
            <!-- <v-col cols="2">
              <v-btn
                v-if="
                  $store.state.contact_name != '' &&
                  $store.state.contact_number != ''
                "
                class="mx-2"
                fab
                color="teal darken-1
"
                dark
                elevation="0"
                @click="_addContacts()"
                ><v-icon x-large>mdi-plus</v-icon></v-btn
              >
            </v-col> -->
          </v-row>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nombre Completo</th>
                  <th class="text-left">N°. Teléfono</th>
                  <th class="text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item of $store.state.itemsListContactEdit"
                  :key="item.id"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.number }}</td>
                  <td>
                    <!-- <v-btn @click="_deleteContacts(i)" fab x-small elevation="0"
                      ><v-icon color="red">mdi-delete</v-icon></v-btn
                    > -->
                    <a
                      fab
                      x-small
                      elevation="0"
                      color="green"
                      dark
                      class="ml-5"
                      target="_blank"
                      style="text-decoration: none"
                      :href="`https://wa.me/51${item.number}`"
                      ><v-icon color="green">mdi-whatsapp</v-icon></a
                    >
                    <a
                      fab
                      x-small
                      elevation="0"
                      dark
                      class="ml-5"
                      style="text-decoration: none"
                      :href="`tel:+${item.number}`"
                      ><v-icon color="blue-grey darken-3">mdi-phone</v-icon></a
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="$store.state.modalContactsEdit = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="$store.state.modalContacts" max-width="750">
      <v-card>
        <v-card-title class="text-h5"> Contactos </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="7">
              <v-text-field
                label="Nombre Completo"
                v-model="$store.state.contact_name"
              >
              </v-text-field>
            </v-col>

            <v-col cols="3">
              <v-text-field
                v-model="$store.state.contact_number"
                label="N°. Teléfono"
              >
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn
                v-if="
                  $store.state.contact_name != '' &&
                  $store.state.contact_number != ''
                "
                class="mx-2"
                fab
                color="teal darken-1
"
                dark
                elevation="0"
                @click="_addContacts()"
                ><v-icon x-large>mdi-plus</v-icon></v-btn
              >
            </v-col>
          </v-row>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nombre Completo</th>
                  <th class="text-left">N°. Teléfono</th>
                  <th class="text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, i) of $store.state.itemsListContact"
                  :key="item.id"
                >
                  <td>{{ item.name }}</td>
                  <td>{{ item.number }}</td>
                  <td>
                    <v-btn @click="_deleteContacts(i)" fab x-small elevation="0"
                      ><v-icon color="red">mdi-delete</v-icon></v-btn
                    >
                    <a
                      fab
                      x-small
                      elevation="0"
                      color="green"
                      dark
                      class="ml-5"
                      target="_blank"
                      style="text-decoration: none"
                      :href="`https://wa.me/51${item.number}`"
                      ><v-icon color="green">mdi-whatsapp</v-icon></a
                    >
                    <a
                      fab
                      x-small
                      elevation="0"
                      dark
                      class="ml-5"
                      style="text-decoration: none"
                      :href="`tel:+${item.number}`"
                      ><v-icon color="blue-grey darken-3">mdi-phone</v-icon></a
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="$store.state.modalContacts = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card> </v-dialog
    ><v-dialog v-model="$store.state.modalCuentas" max-width="750">
      <v-card>
        <v-card-title class="text-h5"> Cuentas </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="2">
              <v-autocomplete
                v-model="$store.state.accountIdTypeAccount"
                :items="$store.state.itemsDataAccountList"
                item-text="name"
                item-value="id"
                label="Cuenta"
              ></v-autocomplete>
            </v-col>

            <v-col cols="2">
              <v-autocomplete
                v-model="$store.state.accountIdBanks"
                :items="$store.state.itemsDataBanksList"
                item-text="acronym"
                item-value="id"
                label="Banco"
              ></v-autocomplete>
            </v-col>

            <v-col cols="2">
              <v-autocomplete
                v-model="$store.state.accountIdCoins"
                :items="$store.state.itemsCoinsList"
                item-text="acronym"
                item-value="id"
                label="Moneda"
              ></v-autocomplete>
            </v-col>

            <v-col cols="4">
              <v-text-field
                type="number"
                label="N°. Cuenta"
                v-model="$store.state.accountNumer"
              >
              </v-text-field>
            </v-col>

            <v-col cols="1">
              <v-btn
                v-if="$store.state.accountNumer != ''"
                class="mx-2"
                fab
                color="teal darken-1
"
                dark
                elevation="0"
                @click="_setAccounts()"
                ><v-icon x-large>mdi-plus</v-icon></v-btn
              >
            </v-col>
          </v-row>
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Cuenta</th>
                  <th class="text-left">Banco</th>
                  <th class="text-left">Moneda</th>
                  <th class="text-left">Número de cuenta</th>
                  <th class="text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item of $store.state.itemsDataAccountsNumberList"
                  :key="item.id"
                >
                  <td>{{ item.nameAccount }}</td>
                  <td>{{ item.nameBanks }}</td>
                  <td>{{ item.nameCoins }}</td>
                  <td>{{ item.accountNumber }}</td>
                  <td>
                    <v-btn
                      @click.native="_delAccount(item.id)"
                      color="white"
                      elevation="0"
                      ><v-icon color="error">mdi-delete</v-icon></v-btn
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="$store.state.modalCuentas = false"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent width="600" v-model="$store.state.dialogVersion">
      <v-card>
        <v-card-title> NUEVA ACTUALIZACIÓN </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col cols="4">
                <img
                  class="mt-4"
                  width="80%"
                  src="../public/img/svg/update.svg"
                  alt=""
                />
              </v-col>
              <v-col cols="8">
                <p style="font-size: 18px">
                  Hola <br /><br />
                  Tenemos una nueva actualización disponible, por favor presiona
                  CTRL + F5 para actualizar tu sistema.

                  <br /><br />
                  En el caso de los dispositivos moviles, debes salir e ingresar
                  al sistema hasta que el mensaje desaparezca.
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <audio
      v-if="newquote"
      src="../public/sound/Messenger Tono de Mensaje - Sonido.mp3"
      autoplay
      loop
      controls
    ></audio>
  </v-app>
</template>

<script>
import Swal from "sweetalert2";
import { mapActions, mapState } from "vuex";

export default {
  name: "App",
  data() {
    return {
      newquote: false,
    };
  },
  methods: {
    ...mapActions([
      "_addPhones",
      "_addContacts",
      "validateVersion",
      "GetTotalCotizacion",
    ]),
    _deleteContacts(i) {
      this.$store.state.itemsListContact.splice(i, 1);
    },
    _deletePhones(index) {
      this.$store.state.itemsListPhone.splice(index, 1);
    },
    async validateTotal() {
      if (JSON.parse(localStorage.getItem("totalCotizacion"))) {
        let totalCotizacion = JSON.parse(
          localStorage.getItem("totalCotizacion")
        );
        await this.GetTotalCotizacion();
        let val = true;
        totalCotizacion.forEach((element) => {
          let validate =
            this.$store.state.calculadoras.listTotalCotizacion.filter(
              (v) => v.rtype == element.rtype && v.total == element.total
            ).length;
          val = validate == 0 ? false : val;
        });
        if (!val) {
          this.newquote = true;
          Swal.fire({
            icon: "warning",
            text: "Se a registrado una nueva cotización.",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              this.newquote = false;
              localStorage.setItem(
                "totalCotizacion",
                JSON.stringify(
                  this.$store.state.calculadoras.listTotalCotizacion
                )
              );
            }
          });
        }
      } else {
        await this.GetTotalCotizacion();
        localStorage.setItem(
          "totalCotizacion",
          JSON.stringify(this.$store.state.calculadoras.listTotalCotizacion)
        );
      }
    },
  },
  computed: {
    ...mapState(["overlay"]),
  },
  async mounted() {
    await this.validateVersion();
    await this.validateTotal();

    setInterval(() => {
      this.validateVersion();
      this.validateTotal();
    }, 15000);
  },

  beforeMount() {
    localStorage.setItem("version", process.env.VUE_APP_VERSION);
  },
};
</script>
<style>
* {
  box-sizing: border-box !important;
  font-size: 0.9rem;
}
.col-md-6 .col-sm-6 {
  padding: 3px 14px !important;
}
.borderCard {
  border: 1px solid rgb(0, 0, 0);
  border-radius: 1rem;
  background: whitesmoke;
}
</style>
