import axios from "axios";
import Swal from "sweetalert2";
// import funcion from "@/mixins/funciones";
const actions = {
  /**
   * DATOS PRINCIPALES
   */
  /* ACCIÓN PARA OBTENER LOS STATUS DE LA COTIZACIÓN */
  async getQuoteStatus() {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = {
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getQuoteStatus",
      headers: headers,
      data: data,
    };
    let response = await axios(config);
    let valores = [];
    response.data.data.forEach((element) => {
      valores.push({
        value: element.id,
        text: element.name,
        code: element.code,
      });
    });
    this.state.status = valores;
  },
  /* ACCIÓN PARA OBTENER LOS EJECUTIVOS */
  async getEntities() {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    let data = {
      id_module: 6,
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getModulesEntities",
      headers: headers,
      data: data,
    };
    let response = await axios(config);
    let valores = [];
    response.data.data.forEach((element) => {
      valores.push({
        value: element.id_entitie,
        text: element.name,
      });
    });
    this.state.entities = valores;
  },
  /* OBTENER SENTIDO */
  async getModality() {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = {
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getModality",
      headers: headers,
      data: data,
    };
    let response = await axios(config);
    let valores = [];
    response.data.data.forEach((element) => {
      valores.push({
        value: element.id,
        text: element.name,
      });
    });
    this.state.modality = valores;
  },

  /* OBTENER TIPO DE CARGA */
  async getShipment() {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = {
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getShipment",
      headers: headers,
      data: data,
    };
    let response = await axios(config);
    let valores = [];
    response.data.data.forEach((element) => {
      valores.push({
        value: element.id,
        text: element.embarque,
        idTransporte: element.id_transport,
      });
    });
    this.state.shipment = valores;
  },
  /* OBTENER ICOTERMS */
  async getIncoterms({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = {
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getIncoterms",
      headers: headers,
      data: data,
    };
    let response = await axios(config);
    let valores = [];
    response.data.data.forEach((element) => {
      valores.push({
        value: element.id,
        text: element.name + " - " + element.description,
        name: element.name ? element.name : "",
      });
    });

    this.state.incoterm = valores;
    commit("setDialog", false);
  },
  /* PUERTO DE ORIGEN */
  async getPortBegin({ commit }, transporte) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    let data = {
      id_transport: transporte,
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getPortBegin",
      headers: headers,
      data: data,
    };
    let response = await axios(config);
    let valores = [];
    response.data.data.forEach((element) => {
      valores.push({
        value: element.id_port,
        text: element.name,
        begend: element.id_begend,
      });
    });
    commit("setPortBegin", valores);
  },
  /* PUERTO DE DESTINO */ async getPortEnd({ commit }, transporte) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    let data = {
      id_transport: transporte,
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getPortEnd",
      headers: headers,
      data: data,
    };
    let response = await axios(config);
    let valores = [];
    response.data.data.forEach((element) => {
      valores.push({
        value: element.id_port,
        text: element.name,
        begend: element.id_begend,
      });
    });
    commit("setPortEnd", valores);
    commit("setDialog", false);
  },
  async getQuoteList({ commit }) {
    commit("setDialog", true);
    var data = {
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    let header = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getQuoteList",
      headers: header,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        commit("setQuoteList", response.data.data);
        commit("setDialog", false);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async getQuoteCall({ commit }, filtro) {
    var data = {
      id_branch: JSON.parse(localStorage.getItem("branch")),
      id_estado: filtro.id_estado,
      id_sentido: filtro.id_sentido,
      id_carga: filtro.id_carga,
      id_icoterms: filtro.id_icoterms,
      desde: filtro.desde,
      hasta: filtro.hasta,
    };

    let header = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_REPORT + "listado_llamadas",
      headers: header,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        commit("setQuoteCall", response.data.data);
        commit("setDialog", false);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async getContainersList({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = {
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getContainersList",
      headers: headers,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        let valores = [];
        response.data.data.forEach((element) => {
          valores.push({ value: element.id, text: element.name });
        });
        commit("setContainersList", valores);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async getBegEndList({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = {
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getBegEndList",
      headers: headers,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        let valores = [];
        response.data.data.forEach((element) => {
          valores.push({
            id: element.id,
            value: element.id,
            text: element.name,
          });
        });
        commit("setbegEndList", valores);
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  async getCityPricing({ dispatch, commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = JSON.stringify({
      idState: 15,
      id_branch: JSON.parse(localStorage.getItem("branch")),
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getCityPricing",
      headers: headers,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        let valores = [];
        response.data.data.forEach((element) => {
          valores.push({
            value: element.id,
            text: element.name,
          });
        });
        commit("setProvincias", valores);
        dispatch("getTown");
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  async getTown({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = JSON.stringify({
      idCity: 128,
      id_branch: JSON.parse(localStorage.getItem("branch")),
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getTown",
      headers: headers,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        let valores = [];
        response.data.data.forEach((element) => {
          valores.push({
            value: element.id,
            text: element.name,
          });
        });
        commit("setDistritos", valores);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async getModuleRole({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = JSON.stringify({
      id_module: 6,
      id_branch: JSON.parse(localStorage.getItem("branch")),
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getModuleRole",
      headers: headers,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        let valores = [];
        response.data.data.forEach((element) => {
          valores.push({
            id: element.id,
            value: element.id_role,
            text: element.name,
            id_role: element.id_role,
          });
        });
        commit("setRole", valores);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async getInstructivoId({ commit }, id) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = {
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getInstructivoId/" + id,
      headers: headers,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        commit("setInstructivo", response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async getFilesQuote({ commit }, id) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = {
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getFilesQuote/" + id,
      headers: headers,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        commit("setListFiile", response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async getMultiplicador({ commit }, datos) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = {
      id_shipment: datos.id,
      containers: datos.containers
        ? datos.containers
        : this.state.datosPrincipales.containers,
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    //
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getMultiplicador",
      data: data,
      headers: headers,
    };
    await axios(config)
      .then(function (response) {
        let valores = [];
        response.data.data.forEach((element) => {
          valores.push({
            id: element.id,
            value: element.id,
            code: element.code,
            text: element.name,
            valor: element.valor ? element.valor : 0,
          });
        });

        commit("setMultiplicador", valores);
        commit("setDialog", false);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async deletePath(__, id) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = {
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + `deletePath/${id}`,
      headers: headers,
      data: data,
    };
    await axios(config).catch(function (error) {
      console.log(error);
    });
  },

  /*------------------------------------*/

  async _getEntities({ commit }, role) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    this.state.itemsEntities = [];
    var data = JSON.stringify({
      id_role: role,
      id_branch: JSON.parse(localStorage.getItem("branch")),
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getEntitiesList",
      headers: headers,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        commit("_setEntities", response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  _getMenuItem({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    this.state.dataMenu = false;
    var dataUser = JSON.parse(localStorage.getItem("datos"));
    var data = JSON.stringify({
      id_entitie: dataUser.id_entitie,
      id_module: 1,
      id_group: 1,
      id_branch: JSON.parse(localStorage.getItem("branch")),
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getMenuModules",
      headers: headers,
      data: data,
    };

    axios(config)
      .then(function (response) {
        commit("_setItemsMenu", response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  _getGroupList({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var dataUser = JSON.parse(localStorage.getItem("datos"));
    var data = JSON.stringify({
      id_entitie: dataUser.id_entitie,
      id_module: 1,
      id_branch: JSON.parse(localStorage.getItem("branch")),
    });

    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getGroupList",
      headers: headers,
      data: data,
    };

    axios(config)
      .then(function (response) {
        commit(
          "_setItemsList",
          response.data.data.filter((v) => v.id_group == 1)
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async _getPhone({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = {
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    var config = {
      method: "post",
      url:
        process.env.VUE_APP_URL_MAIN +
        "getPhone/" +
        this.state.id_entitie_selected,
      headers: headers,
      data: data,
    };

    await axios(config)
      .then(function (response) {
        commit("_setItemsPhone", response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  async _getContacts({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = {
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    var config = {
      method: "post",
      url:
        process.env.VUE_APP_URL_MAIN +
        "getContacts/" +
        this.state.id_entitie_selected,
      headers: headers,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        commit("_setItemsContacts", response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async _getDocumentsPais({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = JSON.stringify({
      id_pais: 139,
      id_branch: JSON.parse(localStorage.getItem("branch")),
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getDocumentsList",
      headers: headers,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        commit("_setDocumentsPais", response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async _getPais({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = {
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getPais",
      headers: headers,
      data: data,
    };

    await axios(config)
      .then(function (response) {
        commit("_setPais", response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async _getRole({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = {
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getRole",
      headers: headers,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        commit("_setItemsDataRoleList", response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  async _getState({ commit }, idPais) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = JSON.stringify({
      idPais: idPais,
      id_branch: JSON.parse(localStorage.getItem("branch")),
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getState",
      headers: headers,
      data: data,
    };

    await axios(config)
      .then(function (response) {
        commit("_setState", response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async _getCity({ commit }, idState) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = JSON.stringify({
      idState: idState,
      id_branch: JSON.parse(localStorage.getItem("branch")),
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getCity",
      headers: headers,
      data: data,
    };

    await axios(config)
      .then(function (response) {
        commit("_setCity", response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  async _getTown({ commit }, idCity) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = JSON.stringify({
      id_branch: JSON.parse(localStorage.getItem("branch")),
      idCity: idCity,
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getTown",
      headers: headers,
      data: data,
    };

    await axios(config)
      .then(function (response) {
        commit("_setTown", response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async _getSex({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = {
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getSex",
      headers: headers,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        commit("_setSex", response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async _getAgents({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = JSON.stringify({
      id_role: 1,
      id_branch: JSON.parse(localStorage.getItem("branch")),
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getEntitiesList",
      headers: headers,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        commit("_setAgents", response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async _getConsigners({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = JSON.stringify({
      id_role: 11,
      id_branch: JSON.parse(localStorage.getItem("branch")),
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getEntitiesList",
      headers: headers,
      data: data,
    };

    await axios(config)
      .then(function (response) {
        commit("_setConsigners", response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  async _getNotify({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = JSON.stringify({
      id_branch: JSON.parse(localStorage.getItem("branch")),
      id_role: 11,
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getEntitiesList",
      headers: headers,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        commit("_setNotify", response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  async _getNavieras({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = JSON.stringify({
      id_role: 2,
      id_branch: JSON.parse(localStorage.getItem("branch")),
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getEntitiesList",
      headers: headers,
      data: data,
    };

    await axios(config)
      .then(function (response) {
        commit("_setItemsNaviera", response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  async _getColoaders({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = JSON.stringify({
      id_role: 17,
      id_branch: JSON.parse(localStorage.getItem("branch")),
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getEntitiesList",
      headers: headers,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        commit("_setColoaders", response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  _addPhones() {
    this.state.itemsListPhone.push({
      id: this.state.phone_id.id,
      name: this.state.phone_id.name,
      number: this.state.phone_number,
    });
    this.state.phone_id = "";
    this.state.phone_number = "";
  },
  _addContacts() {
    this.state.itemsListContact.push({
      name: this.state.contact_name.toUpperCase(),
      number: this.state.contact_number,
    });

    this.state.contact_name = "";
    this.state.contact_number = "";
  },
  _deletePhones(index) {
    alert(index);
    this.state.itemsListPhone.splice(index, 1);
  },
  _setItemsDataAccountsNumberList(state, data) {
    state.itemsDataAccountsNumberList = data;
  },
  _setItemsDataBanksList(state, data) {
    state.itemsDataBanksList = data;
  },
  _setItemsCoinsList(state, data) {
    state.itemsCoinsList = data;
  },
  async obtenerDatosCliente({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = JSON.stringify({
      id_role: 18,
      id_branch: JSON.parse(localStorage.getItem("branch")),
    });
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getEntitiesList",
      headers: headers,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        let valores = [];
        response.data.data.forEach((element) => {
          valores.push({
            id: element.id,
            namelong: element.namelong,
            documentlong: element.documentlong,
            phone: element.phone,
          });
        });
        commit("setDatosClientes", valores);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async getMarketingList({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = {
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getMarketingList",
      headers: headers,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        let data = response.data.data;
        let valores = [];
        data.forEach((element) => {
          valores.push({
            text: element.name,
            value: element.id,
            // disabled: !element.status,
          });
        });
        commit("setMarketingList", valores);
      })
      .catch(function (error) {
        console.log(error);
      });
  },

  async listQuoteOpen({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = {
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_REPORT + "listado_cotizacion_abierta",
      headers: headers,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        let data = response.data.data;
        commit("setQuoteOpen", data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async getReportsRangeDays({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = {
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getReportsRangeDays",
      headers: headers,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        let data = response.data;
        commit("setReportsRangeDays", data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async getItemsServices({ commit, dispatch }, datos) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    let data = {
      id_modality: datos.id_modality,
      id_shipment: datos.id_shipment,
      id_incoterms: datos.id_incoterms,
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getItemsServices",
      headers: headers,
      data: data,
    };
    await axios(config).then(function (response) {
      let valores = response.data.data;
      let servicios = [];
      for (let i = 0; i < valores.length; i++) {
        valores[i].groupservice.forEach((element) => {
          // let d = servicios.filter((v) => v.id == element.id).length;
          // console.log(d);
          servicios.push({
            id_groupservices: element.id_groupservices,
            id_incoterms: element.id_incoterms,
            id_modality: element.id_modality,
            id_multiplicador: element.id_multiplicador
              ? element.id_multiplicador
              : "N",
            id_services: element.id_services,
            id_shipment: element.id_shipment,
            namebegend: element.namebegend,
            namegroupservice: element.namegroupservice,
            namemultiplicador: element.namemultiplicador,
            namerole: element.namerole,
            price: element.price,
            id: element.id,
            id_begend: element.id_begend,
            nameservice: element.nameservice,
            status: element.status,
            id_role: element.id_role,
            esventaflag: 0,
            statedelete: 0,
            servicioFlag: 1,
            costosflag: 0,
            codegroupservices: element.codegroupservices,
            codeItemservices: element.codeItemservices,
          });
        });
      }

      commit("setServices", servicios);
      dispatch("getItemsServicesDetails", data);
    });
  },

  async getItemsServicesDetails({ commit }, datos) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    let data = {
      id_modality: datos.id_modality,
      id_shipment: datos.id_shipment,
      id_incoterms: datos.id_incoterms,
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getItemsServicesDetails",
      headers: headers,
      data: data,
    };

    await axios(config).then(function (response) {
      let servicios = [];
      let valores = response.data.data;
      for (let i = 0; i < valores.length; i++) {
        valores[i].groupservice.forEach((element) => {
          servicios.push({
            id_groupservices: element.id_groupservices,
            id_incoterms: element.id_incoterms,
            id_modality: element.id_modality,
            id_multiplicador: element.id_multiplicador
              ? element.id_multiplicador
              : "N",
            id_services: element.id_services,
            id_shipment: element.id_shipment,
            namebegend: element.namebegend,
            namegroupservice: element.namegroupservice,
            namemultiplicador: element.namemultiplicador,
            namerole: element.namerole,
            costounitario: element.price ? element.price : 0,
            id: element.id,
            id_begend: element.id_begend,
            nameservice: element.nameservice,
            status: element.status,
            id_role: element.id_role,
            statedelete: 0,
            servicioFlag: 1,
            costosflag: 0,
            cif: parseFloat(0.35),
            seguro: parseFloat(0.45),
            id_proveedor: element.id_proveedor ? element.id_proveedor : 0,
            esopcionflag: 1,
            esventaflag: 0,
            esorigenflag:
              element.id_modality == 1
                ? element.id_begend == 1 ||
                  element.id_begend == 3 ||
                  element.id_begend == 6 ||
                  element.id_begend == 8
                  ? 1
                  : 0
                : element.id_begend == 1 ||
                  element.id_begend == 3 ||
                  element.id_begend == 6 ||
                  element.id_begend == 8
                ? 1
                : 0,
            eslocalflag:
              element.id_modality == 1
                ? (element.id_begend == 2 || element.id_begend == 7) &&
                  element.id_role != 4 &&
                  element.id_role != 3 &&
                  element.id_role != 7
                  ? 1
                  : 0
                : (element.id_begend == 1 || element.id_begend == 6) &&
                  element.id_role != 4 &&
                  element.id_role != 3 &&
                  element.id_role != 7
                ? 1
                : 0,

            esaduanaflag:
              element.id_modality == 1
                ? (element.id_begend == 2 || element.id_begend == 7) &&
                  (element.id_role == 4 || element.id_role == 3)
                  ? 1
                  : 0
                : element.id_begend == 1 &&
                  (element.id_role == 4 || element.id_role == 3)
                ? 1
                : 0,
            esalmacenflag:
              element.id_modality == 1
                ? (element.id_begend == 2 || element.id_begend == 7) &&
                  element.id_role == 7
                  ? 1
                  : 0
                : (element.id_begend == 1 || element.id_begend == 7) &&
                  element.id_role == 7
                ? 1
                : 0,
          });
          servicios.push({
            id_groupservices: element.id_groupservices,
            id_incoterms: element.id_incoterms,
            id_modality: element.id_modality,
            id_multiplicador: element.id_multiplicador
              ? element.id_multiplicador
              : "N",
            id_services: element.id_services,
            id_shipment: element.id_shipment,
            namebegend: element.namebegend,
            namegroupservice: element.namegroupservice,
            namemultiplicador: element.namemultiplicador,
            namerole: element.namerole,
            costounitario: 0,
            id: element.id,
            id_begend: element.id_begend,
            nameservice: element.nameservice,
            status: element.status,
            id_role: element.id_role,
            cif: 0,
            seguro: 0,
            statedelete: 0,
            costosflag: 0,
            id_proveedor: element.id_proveedor,
            esventaflag: 1,
            esorigenflag:
              element.id_modality == 1
                ? element.id_begend == 1 ||
                  element.id_begend == 3 ||
                  element.id_begend == 6 ||
                  element.id_begend == 8
                  ? 1
                  : 0
                : element.id_begend == 1 ||
                  element.id_begend == 3 ||
                  element.id_begend == 6 ||
                  element.id_begend == 8
                ? 1
                : 0,
            eslocalflag:
              element.id_modality == 1
                ? (element.id_begend == 2 || element.id_begend == 7) &&
                  element.id_role != 4 &&
                  element.id_role != 3 &&
                  element.id_role != 7
                  ? 1
                  : 0
                : (element.id_begend == 1 || element.id_begend == 6) &&
                  element.id_role != 4 &&
                  element.id_role != 3 &&
                  element.id_role != 7
                ? 1
                : 0,

            esaduanaflag:
              element.id_modality == 1
                ? (element.id_begend == 2 || element.id_begend == 7) &&
                  (element.id_role == 4 || element.id_role == 3)
                  ? 1
                  : 0
                : element.id_begend == 1 &&
                  (element.id_role == 4 || element.id_role == 3)
                ? 1
                : 0,
            esalmacenflag:
              element.id_modality == 1
                ? (element.id_begend == 2 || element.id_begend == 7) &&
                  element.id_role == 7
                  ? 1
                  : 0
                : (element.id_begend == 1 || element.id_begend == 7) &&
                  element.id_role == 7
                ? 1
                : 0,
          });
        });
      }
      commit("setCostos", servicios);
    });
  },
  async getListadoQuoteCasillero({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var data = {
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_REPORT +
        "lista_quote_casillero/" +
        JSON.parse(localStorage.getItem("branch")),
      headers: headers,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        let data = response.data;
        if (data.estado) {
          commit("setListadoQuoteCasillero", data.data);
        }
        // commit("setListadoQuoteCasiller", data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async registarCasillero(__, data) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    data.id_branch = JSON.parse(localStorage.getItem("branch"));
    let id = "";
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_REPORT + "insertar_casillero",
      headers: headers,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        let data = response.data;
        id = data.id;
        if (data.estado) {
          Swal.fire({
            icon: "info",
            title: data.msg,
          });
        }
        // commit("setListadoQuoteCasiller", data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    return id;
  },
  async getListadoCasillero({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_REPORT +
        "listado_casillero/" +
        JSON.parse(localStorage.getItem("branch")),
      headers: headers,
    };
    await axios(config)
      .then(function (response) {
        let data = response.data;

        if (data.data[0].estadoflag) {
          commit("setListadoCasillero", data.data);
        }
        // commit("setInstructivoCasillero", data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async getInstructivoCasillero({ commit }, id) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_REPORT + `obtener_instructivo?id=${id}`,
      headers: headers,
    };
    await axios(config)
      .then(function (response) {
        let data = response.data;
        if (data.estado) {
          commit("setInstructivoCasillero", data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async getQuoteSales({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_REPORT + `quote_sale`,
      headers: headers,
    };
    await axios(config)
      .then(function (response) {
        let data = response.data;
        if (data.statusBol) {
          commit("setQuoteSales", data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async getLockerVer({ dispatch, commit }, id) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_REPORT + `locker_ver/${id}`,
      headers: headers,
    };
    await axios(config)
      .then(function (response) {
        let data = response.data;
        if (data.data[0].estadoflag) {
          commit("setCasilleroVer", data.data[0]);
          commit("setServices", data.data[0].services);
          commit("setCostos", data.data[0].costo);
          dispatch("getMultiplicador", {
            containers: [],
            id: 3,
          });
          dispatch("getInstructivoCasillero", id);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async actualizarCasillero(__, data) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    let id = "";
    var config = {
      method: "put",
      url: process.env.VUE_APP_URL_REPORT + "actualizar_casillero",
      headers: headers,
      data: data,
    };
    await axios(config)
      .then(function (response) {
        let data = response.data;
        id = data.id;
        if (data.estado) {
          Swal.fire({
            icon: "info",
            title: data.msg,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    return id;
  },
  async exportarInstructivoCasillero(__, datos) {
    let headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Accept: "aplication/json",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers":
        "Origin, Content-Type, X-Auth-Token, Authorization, Accept,charset,boundary,Content-Length",
      responseType: "arraybuffer",
    };
    axios
      .get(
        process.env.VUE_APP_URL_REPORT +
          `exportar_instructivo_casillero?id=${datos.id}`,
        headers
      )
      .then((response) => {
        // if (!subir) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute(
          "download",
          `Expediente: ${datos.expediente}` + ".pdf"
        );
        document.body.appendChild(link);
        link.click();
        this.dialogInstructivo = false;
        // } else {
        // this.guardarRutaPreview(response.data);
        // }
      });
  },
  async recotizarCasillero(__, datos) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var config = {
      method: "put",
      url: process.env.VUE_APP_URL_REPORT + "recotizar_casillero",
      headers: headers,
      data: datos,
    };
    await axios(config)
      .then(function (response) {
        let data = response.data;
        if (data.estado == true) {
          Swal.fire(data.msg);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async obtenerListadoLlamadas({ commit }, id) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getCalls",
      headers: headers,
      data: {
        id_quote: id,
        id_branch: JSON.parse(localStorage.getItem("branch")),
      },
    };
    let valores = await axios(config)
      .then(function (response) {
        let data = response.data;
        return data.data;
      })
      .catch(function (error) {
        console.log(error);
      });
    commit("setLlamadas", valores);
  },

  async cargarBranch({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_MAIN +
        `CargarBranch/${JSON.parse(localStorage.getItem("datos")).id}`,
      headers: headers,
    };
    let valores = await axios(config)
      .then(function (response) {
        let data = response.data.data;
        // let arrayData = [];

        // data.forEach((element) => {
        //   arrayData.push({ value: element.id, text: element.trade_name });
        // });
        return data;
      })
      .catch(function (error) {
        console.log(error);
      });

    commit("setBranch", valores);
  },
  async validarCliente(__, documento) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_MAIN +
        `validationDocument?document=${documento}`,
      headers: headers,
    };
    let valores = await axios(config);
    return valores.data;
  },

  async CargarMoneda({ commit }) {
    var vm = this;
    vm.state.overlay = true;
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_MAIN + `cargar_moneda`,
      headers: headers,
    };
    let valores = await axios(config);
    if (valores.data.estadoflag) {
      commit("setMonedaCalc", valores.data.data);
    } else {
      Swal.fire({
        icon: "info",
        title: valores.data.mensaje,
      });
    }
    vm.state.overlay = false;
  },
  async CargarPais({ commit }) {
    var vm = this;
    vm.state.overlay = true;
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_MAIN + `cargar_pais_calc`,
      headers: headers,
    };
    let valores = await axios(config);
    if (valores.data.estadoflag) {
      commit("setPaisCalc", valores.data.data);
    } else {
      Swal.fire({
        icon: "info",
        title: valores.data.mensaje,
      });
    }
    vm.state.overlay = false;
  },
  async CargarPuertoPorTipoPorPais(__, data) {
    var vm = this;
    vm.state.overlay = true;
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var config = {
      method: "get",
      url:
        process.env.VUE_APP_URL_MAIN +
        `CargarPuertoXPaisXTipo/${data.id_pais}/${data.tipo}`,
      headers: headers,
    };
    let valores = await axios(config);
    if (!valores.data.estadoflag) {
      Swal.fire({
        icon: "info",
        title: valores.data.mensaje,
      });
    }
    vm.state.overlay = false;
    return valores.data.data;
  },
  async CargarnavieraCalc({ commit }) {
    var vm = this;
    vm.state.overlay = true;
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_MAIN + `cargar_navieras`,
      headers: headers,
    };
    let valores = await axios(config);
    if (valores.data.estadoflag) {
      commit("setnavieraCalc", valores.data.data);
    } else {
      Swal.fire({
        icon: "info",
        title: valores.data.mensaje,
      });
    }
    vm.state.overlay = false;
  },
  async CargarcontendedorCalc({ commit }) {
    var vm = this;
    vm.state.overlay = true;
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };
    var config = {
      method: "get",
      url: process.env.VUE_APP_URL_MAIN + `cargar_contenedores`,
      headers: headers,
    };
    let valores = await axios(config);
    if (valores.data.estadoflag) {
      commit("setcontendedorCalc", valores.data.data);
    } else {
      Swal.fire({
        icon: "info",
        title: valores.data.mensaje,
      });
    }
    vm.state.overlay = false;
  },
  async validateVersion({ commit }) {
    // var vm = this;
    let data = {
      modulo: "pricing",
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getVersion",
      headers: {
        "auth-token": localStorage.getItem("auth-token"),
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(function (response) {
        if (response.data.data[0].version != localStorage.getItem("version")) {
          commit("SET_DIALOG_VERSION", true);
        } else {
          commit("SET_DIALOG_VERSION", false);
        }

        // if (response.data.data[0].power != 1) {
        //   vm.dialogPower = true;
        // } else {
        //   vm.dialogPower = false;
        // }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  async getEntitiesEjecutivo({ commit }) {
    var headers = {
      "auth-token": JSON.parse(localStorage.getItem("token")),
      "Content-Type": "application/json",
    };

    let data = {
      id_module: 6,
      id_branch: JSON.parse(localStorage.getItem("branch")),
    };
    var config = {
      method: "post",
      url: process.env.VUE_APP_URL_MAIN + "getModulesEntities",
      headers: headers,
      data: data,
    };
    await axios(config).then((response) => {
      commit("setEntities", response.data.data);
    });
  },
};
export default actions;
