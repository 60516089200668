import Vue from "vue";
import Vuex from "vuex";
import state from "@/store/state";
import mutations from "@/store/mutations";
import actions from "@/store/actions";
import calculadoras from "../store/modules/calculadora";
Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters: {
    fclflag: (state) => state.fclflag,
    step1flag: (state) => state.step1flag,
    step2flag: (state) => state.step2flag,
    step3flag: (state) => state.step3flag,

    datosVerGet(state) {
      let datos = state.datosVer;
      return datos;
    },
  },
  modules: {
    calculadoras,
  },
});
